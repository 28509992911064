<!-- 拷贝自精品教辅 -->
<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="boutique">
    <div class="selection">
      <!-- 最热微课 -->
      <div class="hot">
        <div class="selection-header">
          <div class="title">最热微课</div>
        </div>
        <div class="selection-list">
          <div
            v-for="(item, key) in hotestTestPaper"
            :key="key"
            class="list-item"
          >
            <div class="paper-name" @click="planView(item.id)">
              <img src="@/assets/imgs/resource/list.png" alt="" />
              <span> {{ item.schoolName }}{{ item.name }} </span>
            </div>
            <div class="times">
              {{ formatDate(item.createTime || "") }}
            </div>
          </div>
        </div>
      </div>
      <!-- 最新微课 -->
      <div class="new">
        <div class="selection-header">
          <div class="title">最新微课</div>
        </div>
        <div class="selection-list">
          <div
            v-for="(item, key) in latestTestPaper"
            :key="key"
            class="list-item"
          >
            <div class="paper-name" @click="planView(item.id)">
              <img src="@/assets/imgs/resource/list.png" alt="" />
              <span> {{ item.schoolName }}{{ item.name }} </span>
            </div>
            <div class="times">
              {{ formatDate(item.createTime || "") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="screen-options">
        <div class="label" @click="getClassifiedPapers(0, 0)">全部微课</div>
        <catalogueList @getNewViewData="getNewViewData"></catalogueList>
        <!-- <div v-for="(item, key) in paperTypeData" :key="key" class="grade">
          <div class="grade-name">
            {{ item.label + "(" }}{{ item.count + ")" }}
          </div>
          <div class="paper-type">
            <div
              v-for="(val, index) in item.children"
              :key="index"
              class="type-name"
              :class="{
                'current-type':
                  val.type == currentSelectedPaperType &&
                  searchItems.resGradeId == item.value
              }"
              @click="getClassifiedPapers(item.value, val.type)"
            >
              {{ val.label + "(" }}{{ val.count + ")" }}
            </div>
          </div>
        </div> -->
      </div>
      <div class="screen-result">
        <div class="more-screen">
          <!-- <div class="keyword more-item">
            <el-input
              v-model="searchItems.keyword"
              class="more-input"
              placeholder=""
              prefix-icon="el-icon-search"
              @keyup.enter.native="getPaperList()"
            ></el-input>
          </div> -->
          <div class="year more-item">
            <div class="label">年份：</div>
            <div
              v-for="(item, key) in paperYears"
              :key="key"
              class="year-item"
              :class="{ 'current-year': currentYear == item.value }"
              @click="getYearsPapers(item.value)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="sort-box">
          <div
            class="sort-type"
            :class="{ current: isCurrentSortType == 'new' }"
            @click="getLastestPapers"
          >
            最新
          </div>
          <div
            class="sort-type"
            :class="{ current: isCurrentSortType == 'hot' }"
            @click="getHotestPapers"
          >
            浏览
            <i
              class="el-icon-sort-down sort-icon"
              :class="{ 'current-sort': searchItems.viewOrder == 2 }"
            ></i>
            <i
              class="el-icon-sort-up sort-icon"
              :class="{ 'current-sort': searchItems.viewOrder == 1 }"
            ></i>
          </div>
        </div>
        <div class="paper-list">
          <div v-if="paperList.length > 0" class="has-data">
            <div
              v-for="(item, i) in paperList"
              :key="i"
              class="item-list"
              :class="{ mr20: (i + 1) % 3 != 0 }"
              @click="showDetail(item)"
            >
              <!-- 缩略图 -->
              <div class="thumbnail">
                <img :src="item.imgCover" alt="" />
              </div>
              <div class="info">
                <div class="base-info">
                  <div class="name" @click="toDetail(item.id)">
                    {{ item.name }}
                  </div>
                  <div class="chapters">
                    共{{ item.countLesson }}节
                    <span style="margin: 0 8px">|</span>
                    {{ item.countLearn }}人已学
                  </div>
                </div>
                <div class="more-info">
                  <div class="owner-ship">
                    {{
                      getLabelByValue(item.status % 3, microLessonsOwnership)
                    }}
                  </div>
                  <div class="fav">
                    <span>收藏</span>
                    <i
                      v-if="item.isStar == 1"
                      class="el-icon-star-on"
                      @click="cancelFavorite(item)"
                    ></i>
                    <i
                      v-else
                      class="el-icon-star-off"
                      @click="addFavoriteList(item)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="no-data">
            <img src="@/static/resources/none.svg" alt="" />
            <p style="text-align: center; color: #999999">暂未找到数据</p>
          </div>
        </div>
      </div>
    </div>
    <!-- begin: pagination-->
    <div v-if="paperList.length > 0" class="pagination">
      <div class="page-totle">共{{ totalPage }}条</div>
      <span>第</span>
      <el-select
        v-model="pageIndex"
        placeholder="1"
        class="page-select"
        @change="pageSelect"
      >
        <el-option
          v-for="item in Math.ceil(totalPage / pageSize)"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <span style="margin-right: 64px"
        >页，共<span style="padding: 0 8px">{{
          Math.ceil(totalPage / pageSize)
        }}</span
        >页</span
      >
      <ul class="page-jump">
        <li>
          <a v-if="pageIndex > 1" @click="toFirstPage">
            <img src="@/assets/imgs/analy/icon-page-first.png" alt="第一页" />
          </a>
          <a v-else>
            <img
              src="@/assets/imgs/analy/icon-page-first-50.png"
              alt="第一页"
            />
          </a>
        </li>
        <li>
          <a v-if="pageIndex > 1" @click="pageIndex--, pageClick()">
            <img
              src="@/assets/imgs/analy/icon-page-previous.png"
              alt="上一页"
            />
          </a>
          <a v-if="pageIndex == 1">
            <img
              src="@/assets/imgs/analy/icon-page-previous-50.png"
              alt="上一页"
            />
          </a>
        </li>
        <li>
          <a
            v-if="pageIndex != Math.ceil(totalPage / pageSize)"
            @click="pageIndex++, pageClick()"
          >
            <img src="@/assets/imgs/analy/icon-page-next.png" alt="下一页" />
          </a>
          <a v-else>
            <img src="@/assets/imgs/analy/icon-page-next-50.png" alt="下一页" />
          </a>
        </li>
        <li>
          <a
            v-if="pageIndex != Math.ceil(totalPage / pageSize)"
            @click="toLastPage"
          >
            <img src="@/assets/imgs/analy/icon-page-last.png" alt="最后一页" />
          </a>
          <a v-else>
            <img
              src="@/assets/imgs/analy/icon-page-last-50.png"
              alt="最后一页"
            />
          </a>
        </li>
      </ul>
    </div>
    <!-- end: pagination-->
    <!--试卷分析弹窗-->
    <ExamPaperAnalysisForm
      v-if="analysisDialogVisible"
      ref="ExamPaperAnalysisForm"
    ></ExamPaperAnalysisForm>
  </div>
</template>
<script>
import {
  subjectOptions,
  gradeOptions,
  gradeMiddleOptions,
  primaryOptions,
  gradeHighOptions,
  examTypeOptions,
} from "@/core/util/constdata";
import {
  teachmicrocourserelation,
  getCountOfLessonsTypes,
} from "@/core/api/resource/boutiqueLibrary";
import {
  updateViewCount,
  unfavMicroLesson,
  favMicroLesson,
} from "@/core/api/resource/myResource";
import { mapGetters } from "vuex";
import ExamPaperAnalysisForm from "@/views/resource/form/ExamPaperAnalysisForm";
import { getExamPaperImgZip } from "@/core/api/resource/exampaper";
import { formatDate } from "@/core/util/date";
import catalogueList from "@/components/resource/catalogueList";
const lessonType = [
  {
    label: "知识点/章节微课",
    value: 1,
  },
  {
    label: "专题微课",
    value: 2,
  },
  {
    label: "试题微课",
    value: 3,
  },
];
const microLessonsOwnership = [
  {
    label: "系统",
    value: 0,
  },
  {
    label: "校本",
    value: 1,
  },
  {
    label: "个人",
    value: 2,
  },
];
export default {
  name: "BoutiqueMicroLesson",
  components: {
    ExamPaperAnalysisForm,
    catalogueList,
  },
  data() {
    return {
      fullscreenLoading: false,

      currentLevel: this.$route.query.level, //年级
      currentSubject: this.$route.query.subject,
      backUrl: "",
      // 分类
      paperTypeData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      paperCountOfTypes: [], //各类型试卷数量
      currentSelectedPaperType: 0,
      // 试题
      paperList: [],
      latestTestPaper: [], //最新试卷
      hotestTestPaper: [], //最热试卷
      provinceList: [],
      cityList: [],
      districtList: [],
      areaPopupVisible: false, //省级弹窗
      cityItemsVisible: false, //市级选项展示
      cityPopupVisible: false, //市级弹窗
      districtItemsVisible: false, //区级选项展示
      districtPopupVisible: false, //区级弹窗

      subjectOptions: subjectOptions,
      gradeOptions: gradeOptions,
      gradeMiddleOptions: gradeMiddleOptions,
      primaryOptions: primaryOptions,
      gradeHighOptions: gradeHighOptions,
      examTypeOptions: examTypeOptions,
      lessonType: lessonType,
      microLessonsOwnership: microLessonsOwnership,

      // 类型(type)
      // 最新(latest)   0-不开启 1-开启
      // 浏览(viewOrder) 1-升序 2-降序
      searchItems: {
        level: 0, //学段
        subjectId: 0, //科目
        resGradeId: 0,
        year: 0, //年份
        beforeYear: 0, //更早: 全部-year=0,beforeYear=0 具体某年份-year=年份,beforeYear=0 更早-year=0,beforeYear=列举出来的最小年份

        fileType: 0, //微课文件类型 1-word 2-pdf 3-图片 4-ppt 5-excel
        keyword: "",
        latest: 1, //最新 0-不开启 1-开启
        viewOrder: 2, //浏览量排序: 1-升序 2-降序
      },
      paperType: 0, //type：1-最新 2-最热
      isCurrentSortType: "new",
      currentProvinceId: 0,
      currentCityId: 0,
      currentDistrictId: 0,
      paperYears: [],
      currentYear: 0,
      searchPopupVisibel: false,
      analysisDialogVisible: false, // 试卷分析弹窗

      // 列表项
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,

      CDN: "https://static.wtjy.com/",
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  created() {
    this.searchItems.level = this.getLevelId;
    this.searchItems.subjectId = this.getSubjectId;
    // 各类型教案数量
    getCountOfLessonsTypes({
      level: this.getLevelId,
      subjectId: this.getSubjectId,
    }).then((res) => {
      this.paperCountOfTypes = res.data.data;
      this.paperTypeDataProcessing();
    });
    //筛选教案条件--年份
    let _year = new Date().getFullYear();
    this.paperYears = [{ label: "全部", value: 0 }];
    for (let i = _year; i > _year - 5; i--) {
      let _item = { label: i, value: i };
      this.paperYears.push(_item);
    }
    this.paperYears.push({ label: "更早", value: 1 });
    this.getBaseData();
  },
  methods: {
    showDetail(v) {
      this.$router.push({
        name: "ShowMicroLesson",
        query: { id: v.id },
      });
    },
    getNewViewData(data) {
      Object.assign(this.searchItems, data);
      this.pageIndex = 1;
      this.getPaperList();
    },
    dataIsEmpty(field) {
      if (typeof field === "undefined" || field === null || field === "") {
        return true;
      } else {
        return false;
      }
    },
    nullDataProcessing(field) {
      if (this.dataIsEmpty(field)) {
        return "--";
      } else {
        return field;
      }
    },
    avoidEqualToZero(field) {
      return field + parseInt(10 * Math.random() + 1);
    },
    getLabelByValue(value, source) {
      let _label = "";
      source.forEach((item) => {
        if (item.value == value) {
          _label = item.label;
        }
      });
      return _label;
    },
    getAreaLabelByValue(value, source) {
      let _label = "";
      source.forEach((item) => {
        if (item.value == value) {
          _label = item.label.substring(0, 2);
          if (value == 15 || value == 23) {
            _label = item.label.substring(0, 3);
          }
        }
      });
      return _label;
    },
    setPaperTypeBgcolor(type) {
      return "bgcolor_" + type;
    },
    formatDate(dateStr) {
      if (!dateStr || dateStr.length === 0) {
        return "";
      }
      let date = new Date(dateStr);
      return formatDate(date, "yyyy-MM-dd");
    },
    getAddress(province, city) {
      let _province = "";
      let _city = "";
      if (!this.dataIsEmpty(city)) {
        _city = city.split("-")[0];
      }
      if (!this.dataIsEmpty(province)) {
        _province = province.split("-")[0];
      } else {
        _province = "全国";
        _city = "";
      }

      if (_city == _province) {
        _city = "";
      }
      return _province + _city;
    },
    getRegionNameDisplay(province) {
      let _label = "";
      let val = province.value;
      if (val == "15" || val == "23") {
        _label = province.label.substring(0, 3);
      } else {
        _label = province.label.substring(0, 2);
      }
      return _label;
    },
    //试卷分类数据处理
    paperTypeDataProcessing() {
      this.paperTypeData = this.gradeMiddleOptions;
      if (this.getLevelId == 3) {
        this.paperTypeData = this.gradeHighOptions;
      }
      if (this.getLevelId == 1) {
        this.paperTypeData = this.primaryOptions;
      }
      this.paperTypeData.forEach((item) => {
        item.count = 0; //一级节点统计数count
        item.children = []; //二级节点
        this.paperCountOfTypes.forEach((val) => {
          if (item.value == val.resGradeId) {
            item.count += parseInt(val.count);
            item.children.push(val);
          }
        });
        item.children.forEach((index) => {
          index.label = this.getLabelByValue(index.fileType, this.lessonType);
        });
      });
    },
    getBaseData() {
      this.getPaperForBanner(1);
      this.getPaperForBanner(2);
      this.getPaperList();
    },

    /**
     * banner列表数据
     * type(paperType)：1-最新试卷 2-最热试卷
     */
    getPaperForBanner(type) {
      if (type == 1) {
        this.searchItems.latest = 1;
        teachmicrocourserelation(
          Object.assign(
            {
              current: 1,
              size: 10,
            },
            this.searchItems
          )
        ).then((res) => {
          let _list = res.data.data.records;
          this.latestTestPaper = _list;
        });
      } else if (type == 2) {
        this.searchItems.latest = 0;
        this.searchItems.viewOrder = 2;
        teachmicrocourserelation(
          Object.assign(
            {
              current: 1,
              size: 10,
            },
            this.searchItems
          )
        ).then((res) => {
          let _list = res.data.data.records;
          this.hotestTestPaper = _list;
        });
      }
      // this.getPaperList();
    },
    //试卷列表
    getPaperList() {
      teachmicrocourserelation(
        Object.assign(
          {
            current: this.pageIndex,
            size: this.pageSize,
          },
          this.searchItems
        )
      ).then((res) => {
        let _list = res.data.data.records;
        this.totalPage = res.data.data.total;
        this.pageSize = res.data.data.size;
        _list.forEach((item) => {
          if (item.type == 0) {
            item.type = 99;
          }
        });
        this.paperList = _list;
        //for test
        // for (let i = 1; i < 10; i++) {
        //   let obj = {
        //     id: i,
        //     name: "微课" + i,
        //     imgCover:
        //       "https://static.wtjy.com/res/file/upload/202110/15/B0gv8ryE.png",
        //     countLearn: i,
        //     status: i,
        //     isStar: i % 2,
        //     countLesson: i
        //   };
        //   this.paperList.push(obj);
        // }
      });
    },
    //获取试卷--分类
    getClassifiedPapers(grade, type) {
      this.searchItems.resGradeId = grade;
      this.searchItems.type = type;
      this.currentSelectedPaperType = type;
      this.getPaperList();
    },
    //获取试卷--年份
    getYearsPapers(year) {
      //year:0-全部 1-更早
      if (year == 0) {
        this.currentYear = 0;
        this.searchItems.year = 0;
        this.searchItems.beforeYear = 0;
      } else if (year == 1) {
        this.currentYear = 1;
        this.searchItems.year = 0;
        this.searchItems.beforeYear = new Date().getFullYear() - 5 + 1;
      } else {
        this.currentYear = year;
        this.searchItems.year = year;
        this.searchItems.beforeYear = 0;
      }
      this.getPaperList();
    },

    //获取最新试卷
    getLastestPapers() {
      this.isCurrentSortType = "new";
      this.searchItems.latest = 1;
      this.getPaperList();
    },
    //获取最热试卷--浏览倒序
    getHotestPapers() {
      this.isCurrentSortType = "hot";
      this.searchItems.latest = 0;
      if (this.searchItems.viewOrder == 2) {
        this.searchItems.viewOrder = 1;
      } else {
        this.searchItems.viewOrder = 2;
      }
      this.getPaperList();
    },
    getSelected() {
      this.searchItems.subjectId = this.currentSubject;
      this.searchItems.level = this.currentLevel;
    },
    switchDataAccordingSubject(subject, level) {
      this.currentLevel = level;
      this.currentSubject = subject;
      this.getSelected();
    },
    searchBtn() {},
    showSearchPopup() {
      if (this.searchPopupVisibel) {
        this.searchPopupVisibel = false;
      } else {
        this.searchPopupVisibel = true;
      }
    },
    //查看微课
    planView() {
      updateViewCount();
      // this.$router.push({
      //   name: "TeachingPlanDetails",
      //   query: {
      //     id: id,
      //     backurl: "/res/teachingAids"
      //   }
      // });
      // console.log("微课_");
    },
    toDetail() {},
    //收藏
    addFavoriteList(item) {
      favMicroLesson(item.id).then(() => {
        this.setList(item.id, true);
        this.$message.success("收藏成功");
      });
    },
    cancelFavorite(item) {
      unfavMicroLesson(item.id).then(() => {
        this.setList(item.id, false);
        this.$message.success("取消收藏成功");
      });
    },

    setList(id, type) {
      //type:0-未收藏 1-收藏
      this.paperList.map((index) => {
        if (id == index.id) {
          if (type) {
            index.isStar = 1;
          } else {
            index.isStar = 0;
          }
        }
      });
    },
    downloadTeachingPlan() {},

    //试卷下载
    downloadExamPaper(examPaper) {
      if (examPaper.filePath == null || examPaper.filePath.length === 0) {
        this.$message.error("试卷文件不存在！");
        return;
      }
      if (
        examPaper.filePath.endsWith(".doc") ||
        examPaper.filePath.endsWith(".docx") ||
        examPaper.filePath.endsWith(".pdf")
      ) {
        this.downloadRequest(this.CDN + examPaper.filePath, examPaper.name);
      } else {
        this.fullscreenLoading = true;
        getExamPaperImgZip(examPaper.id).then((response) => {
          this.downloadRequest(response.data.data.url, response.data.data.name);
          this.fullscreenLoading = false;
        });
      }
    },
    downloadRequest(url, name) {
      let request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "blob";
      request.onload = function () {
        let url = window.URL.createObjectURL(request.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      };
      request.send();
    },
    //分页
    btnClick(num) {
      if (num != this.pageIndex) {
        this.pageIndex = num;
      }
    },
    pageClick() {
      this.getPaperList();
    },
    pageSelect() {
      this.btnClick(this.pageIndex);
      this.pageClick();
    },
    toFirstPage() {
      this.pageIndex = 1;
      this.pageSelect();
    },
    toLastPage() {
      this.pageIndex = Math.ceil(this.totalPage / this.pageSize);
      this.pageSelect();
    },
  },
};
</script>

<style lang="scss">
.boutique {
  .page-content {
    .el-input__inner {
      background-color: #f5f5f5;
      border: 1px solid #f5f5f5;
      height: 40px;
    }
    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
      border-color: #2474ed;
    }
  }
}
</style>
<style lang="scss" scoped>
.boutique {
  width: 1220px;
  margin: 0 auto;
  // padding-bottom: 58px;
  padding-top: 58px;
  .selection {
    display: flex;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    color: rgba(0, 0, 0, 0.85);
    .hot,
    .new {
      width: 50%;
      padding: 6px 0 16px 30px;
    }
    .selection-header {
      display: flex;
      justify-content: space-between;
      padding-right: 76px;
      line-height: 24px;
      .title {
        font-weight: 700;
      }
    }
    .selection-list {
      min-height: 20px;
      .list-item {
        display: flex;
        justify-content: space-between;
        line-height: 24px;
        font-weight: 400;
        .paper-name {
          cursor: pointer;
          // display: flex;
          align-items: center;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 400px;
          height: 24px;
          white-space: nowrap;
          img {
            margin-right: 8px;
            width: 15px;
          }
          &:hover {
            color: #2474ed;
          }
        }
        .times {
          color: rgba(0, 0, 0, 0.5);
          padding-right: 88px;
        }
      }
    }
  }
  .page-content {
    display: flex;
    padding-top: 60px;
    .screen-options {
      min-width: 300px;
      padding-left: 30px;
      margin-right: 20px;
      .label {
        font-size: 18px;
        font-weight: 700;
        color: #2474ed;
        margin-bottom: 10px;
        height: 25px;
        cursor: pointer;
      }
      .grade {
        margin-bottom: 20px;
        .grade-name {
          font-size: 14px;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
          height: 20px;
          margin-bottom: 8px;
        }
        .paper-type {
          .type-name {
            font-size: 14px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.5);
            line-height: 20px;
            height: 20px;
            margin-bottom: 8px;
            cursor: pointer;
            &:last-child {
              margin-bottom: 0px;
            }
            &:hover {
              color: #2474ed;
            }
          }
          .current-type {
            color: #2474ed;
          }
        }
      }
    }
    .screen-result {
      padding: 0 35px 0 0;
      .search {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .more-screen {
        .label {
          padding: 5px 8px;
          font-size: 14px;
          font-weight: 600;
        }
        .more-item {
          display: flex;
          &:not(:first-child) {
            margin-top: 10px;
          }
        }
        .keyword {
          display: flex;
          flex-direction: row-reverse;
          .more-input {
            width: 368px;
          }
        }
        .year {
          .year-item {
            display: flex;
            padding: 5px 8px;
            cursor: pointer;
          }
          .current-year {
            background-color: #2474ed;
            color: #ffffff;
          }
        }
      }
      .sort-box {
        line-height: 30px;
        padding: 5px 20px;
        font-size: 14px;
        background: #f4f4f4;
        width: 886px;
        margin: 10px 0 15px;
        display: flex;
        .current {
          color: #2474ed;
        }
        .sort-type {
          font-weight: 500;
          cursor: pointer;
          &:first-child {
            margin-right: 50px;
          }
          .sort-icon {
            width: 6px;
            font-weight: 700;
            color: rgba(0, 0, 0, 0.5);
          }
          .current-sort {
            color: #2474ed;
          }
        }
      }
      .paper-list {
        padding-bottom: 20px;
        .has-data {
          display: flex;
          flex-wrap: wrap;
          .mr20 {
            margin-right: 20px;
          }
          .item-list {
            width: 282px;
            margin-bottom: 32px;
            border: 1px solid #e0e0e0;
            .el-icon-star-off,
            .el-icon-star-on {
              color: $primary-color;
              font-size: 18px;
              vertical-align: bottom;
              cursor: pointer;
            }
            .thumbnail {
              width: 280px;
              height: 150px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .info {
              width: 100%;
              padding: 0 12px;
              .base-info {
                margin-bottom: 20px;
                align-items: center;
                .icon {
                  width: 128px;
                }
                .name {
                  cursor: pointer;
                  font-size: 14px;
                  color: rgba(0, 0, 0, 0.85);
                  margin: 12px 0 8px;
                }
                .chapters {
                  color: rgba(0, 0, 0, 0.5);
                }
              }
              .more-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 18px;
                .owner-ship {
                  color: rgba(255, 171, 0, 1);
                }
                .fav {
                  color: rgba(0, 0, 0, 0.5);
                  span {
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }
        .no-data {
          img {
            width: 400px;
            display: block;
            margin: 20px auto;
          }
        }
      }
    }
  }
  .pagination {
    height: 80px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    line-height: 80px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 64px;
    padding-right: 85px;
    .page-totle {
      margin-right: 64px;
      font-size: 14px;
      text-align: right;
      color: rgba(0, 0, 0, 0.85);
    }
    .page-select {
      width: 80px;
      height: 32px;
      margin: 0 20px 0 8px;
    }
    .page-jump {
      display: flex;
      list-style: none;
      & :not(:last-child) {
        margin-right: 32px;
      }
      & :last-child {
        margin-right: 8px;
      }
      li {
        width: 24px;
        height: 24px;
        img {
          vertical-align: middle;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
</style>
